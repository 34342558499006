import {useState, useRef, useEffect} from "react";
import {getTotalTimeForTimeTrackingSum} from "./time-tracking-utils";
import {api} from "../../lib/api";
import {XRow, XText} from "../../components/xui";
import {getStatusForCard} from "../../lib/card-status-utils";
import XPlainButton from "../../components/xui/XPlainButton";
import {Link} from "react-router-dom";
import useDebounceActions from "../../lib/hooks/useDebounceActions";
import useOnKeepDown from "../../lib/hooks/useOnKeepDown";
import useRouteDependentCardLink from "../../lib/hooks/useRouteDependentCardLink";
import {cx, formatMsAsSec, shrinker, useGlobalKeyPress} from "@cdx/common";
import {useSetTitle} from "../../components/Title";
import {getParentCard} from "../workflows/workflow-utils";
import {timerWidgetStyles as styles} from "./time-tracking.css";
import {pronounceSafeSeq} from "../../lib/sequences";
import {cardStatusVariants} from "@cdx/ds/components/DSCard/DSCardTheme.css";
import {
  DSIconButton,
  DSIconClock,
  DSIconClose,
  DSIconMinus,
  DSIconPlay,
  DSIconPlus,
  DSIconStop,
  Row,
  Box,
} from "@cdx/ds";
import {decorationStyles} from "@cdx/ds/css/decoration.css";
import {layoutStyles} from "@cdx/ds/css/layout.css";

export const useUpdatingTime = (time, isRunning) => {
  const [, update] = useState(0);
  const timeoutRef = useRef(null);
  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);
  if (timeoutRef.current) clearTimeout(timeoutRef.current);
  const msPart = Math.abs(time) % 1000;
  if (!isRunning) return;
  // FIXME: setting `.current` here is unsafe as if the component might get
  // rerendered due to Suspense and the timout won't be cleared
  timeoutRef.current = setTimeout(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    update((u) => u + 1);
  }, 1001 - msPart);
};

const ControlButton = ({status, ...props}) => (
  <XPlainButton size="sm" square color="white" className={styles.controlButton} {...props} />
);

const StoppedTimerControl = ({status, card, user}) => (
  <XRow
    rounded="sm"
    className={cx(cardStatusVariants[status], styles.timerContainer)}
    pa={0}
    sp={0}
    align="center"
  >
    <XRow align="baseline" sp={0} px={0}>
      <Box
        className={styles.timerContainerSecondaryText}
        textTransform="uppercase"
        size={12}
        weight="bold"
      >
        Total
      </Box>
      <XText color={null} size={1} lineHeight="tight" className={styles.timerText}>
        {formatMsAsSec(
          user.$meta.isLoaded
            ? getTotalTimeForTimeTrackingSum(
                card.$meta.first("userTimeTrackingSums", {userId: user.id, $order: "userId"})
              )
            : null
        )}
      </XText>
    </XRow>
    <ControlButton
      status={status}
      onClick={() => api.mutate.timeTracking.start({userId: user.id, cardId: card.id})}
    >
      <DSIconPlay />
    </ControlButton>
  </XRow>
);

export const useTimer = ({segment, time}) => {
  const [updatedBackendVal, setBackendVal] = useState(0);
  const loadedBackendVal = segment.$meta.isFieldLoaded("modifyDurationMsBy")
    ? segment.modifyDurationMsBy
    : updatedBackendVal;

  const sendModifyTime = (val) => {
    if (!val) return;
    const targetVal = Math.round(loadedBackendVal + val * 60 * 1000);
    setBackendVal(targetVal);
    return api.mutate.timeTracking.setModifyDuration({
      id: segment.id,
      modifyDurationMsBy: targetVal,
    });
  };

  const [setModifyTimeBy, debouncedVal] = useDebounceActions({
    initialVal: 0,
    fn: sendModifyTime,
  });

  const shownModifyTimeMs = loadedBackendVal + debouncedVal * 60 * 1000;

  const minusHandlers = useOnKeepDown(() =>
    setModifyTimeBy((prevMins) => {
      const currMins = (time + loadedBackendVal) / 60 / 1000;
      return currMins + prevMins - 5 > 0 ? prevMins - 5 : -currMins;
    })
  );
  const plusHandlers = useOnKeepDown(() => setModifyTimeBy((t) => t + 5));

  return {
    setModifyTimeBy,
    shownModifyTimeMs,
    debouncedVal,
    sendModifyTime,
    minusHandlers,
    plusHandlers,
  };
};

export const RunningTimer = ({segment, status, disabled}) => {
  const getRunningMs = () => {
    const startedAt = segment.$meta.get("startedAt", null);
    if (!startedAt) return 0;
    const runningMs =
      (segment.$meta.get("finishedAt", null) || new Date()).getTime() - startedAt.getTime();
    return runningMs;
  };
  const time = getRunningMs();
  const {
    shownModifyTimeMs,
    setModifyTimeBy,
    debouncedVal,
    sendModifyTime,
    minusHandlers,
    plusHandlers,
  } = useTimer({segment, time});

  useUpdatingTime(time, true);

  useSetTitle(time > 0 ? `[${formatMsAsSec(time + shownModifyTimeMs, {short: true})}] •` : null, {
    pos: 0,
  });

  const handleStop = () => {
    if (debouncedVal) {
      const p = sendModifyTime(debouncedVal).then(() =>
        api.mutate.timeTracking.stop({id: segment.id})
      );
      setModifyTimeBy(() => 0);
      return p;
    } else {
      return api.mutate.timeTracking.stop({id: segment.id});
    }
  };

  return (
    <XRow
      rounded="sm"
      className={cx(cardStatusVariants[status], styles.timerContainer)}
      pa={0}
      sp={0}
      align="center"
    >
      <ControlButton status={status} disabled={disabled} {...minusHandlers}>
        <DSIconMinus />
      </ControlButton>
      <XText size={1} lineHeight="tight" className={styles.timerText}>
        {formatMsAsSec(time + shownModifyTimeMs, {short: true})}
      </XText>
      <ControlButton status={status} disabled={disabled} {...plusHandlers}>
        <DSIconPlus />
      </ControlButton>
      <ControlButton status={status} disabled={disabled} onClick={handleStop}>
        <DSIconStop />
      </ControlButton>
    </XRow>
  );
};

const CardTimer = ({att, user}) => {
  const {card} = att;
  const runningSegment = card.$meta.first("timeTrackingSegments", {
    userId: user.id,
    finishedAt: null,
    $order: "createdAt",
  });
  const status = getStatusForCard(card);
  const cardLink = useRouteDependentCardLink(card);
  const parentCard = getParentCard(card);
  const pTitle = parentCard && parentCard.$meta.get("title", null);

  useGlobalKeyPress({
    fn: () =>
      runningSegment
        ? api.mutate.timeTracking.stop({id: runningSegment.id})
        : api.mutate.timeTracking.start({userId: user.id, cardId: card.id}),
    code: "KeyP",
    description: "Start or Stop Timer",
    withAlt: true,
  });

  return (
    <XRow sp={1} align="center" fillParent>
      {runningSegment ? (
        <RunningTimer segment={runningSegment} status={status} />
      ) : (
        <StoppedTimerControl card={card} user={user} status={status} />
      )}
      <XRow sp={0} align="baseline" relative style={{top: -1}}>
        <XText size={1} color="gray500" lineHeight="tight">
          on
        </XText>
        <XText as={Link} to={cardLink} size={2} color="gray300" lineHeight="tight">
          {user.showCardIdInTimer && (
            <XText
              as="span"
              size={1}
              lineHeight="tight"
              color="gray400"
              style={{marginRight: "0.25rem"}}
            >
              ${pronounceSafeSeq.intToSeq(card.accountSeq)}
            </XText>
          )}
          {pTitle && (
            <XText
              as="span"
              size={1}
              className={styles.heroCardTitle}
              lineHeight="tight"
              color="gray400"
            >
              {shrinker(pTitle, 16)}
            </XText>
          )}
          {shrinker(card.title, 20)}
        </XText>
      </XRow>
      {!runningSegment && (
        <DSIconButton
          variant="tertiary"
          size="sm"
          icon={<DSIconClose />}
          onClick={() => api.mutate.timeTracking.close({id: att.id})}
          negatePadding
          className={layoutStyles.ml.auto}
        />
      )}
    </XRow>
  );
};

const TimerWidget = ({root}) => {
  const user = root.loggedInUser;
  if (!user) return null;
  const att = user.activeTimeTracker;

  if (!att || !att.$meta.isLoaded || !user.$meta.isLoaded || !att.card) return null;

  return (
    <Row
      position="fixed"
      className={styles.container}
      py="4px"
      sp="8px"
      px="8px"
      align="center"
      colorTheme="gray750"
    >
      <DSIconClock size={16} className={decorationStyles.color.primary} />
      <CardTimer att={att} user={user} />
    </Row>
  );
};

export default TimerWidget;

import {createContext, Suspense, useContext, useEffect, useRef} from "react";
import {
  Portal,
  ClickOutside,
  ClickOutsideProvider,
  springConfigs,
  RawSpinner,
  KeyPressContextProvider,
  cx,
} from "@cdx/common";
import Ui from "./ui2";
import {animated, useTransition} from "react-spring";
import {XCol} from "./xui";
import XTextButton from "./xui/XTextButton";
import {modalStyles as styles} from "./Modal.css";
import FocusLock from "react-focus-lock";
import dsStyles from "@cdx/ds/css/index.css";

let modalCount = 0;

export const focusLockKeyGroupContext = createContext(null);
let nextIdx = 1;

export const useFocusLockGroup = () => useContext(focusLockKeyGroupContext);

const withModalClassNames = dsStyles.overflow.hidden.split(" ");

const Modal = ({
  presenceProps,
  onClose,
  hideClose,
  children,
  width,
  cdxContext,
  bg,
  purpleBackdrop,
  blurBackdrop,
  blueBackdrop,
  fixedHeight,
}) => {
  const focusGroupIdxRef = useRef();
  if (!focusGroupIdxRef.current) focusGroupIdxRef.current = nextIdx += 1;
  const focusGroupName = `modal_${focusGroupIdxRef.current}`;

  useEffect(() => {
    if (modalCount === 0) {
      if (document.body.classList) {
        document.body.classList.add(...withModalClassNames);
      }
    }
    modalCount += 1;
    return () => {
      modalCount -= 1;
      if (modalCount === 0) {
        if (document.body.classList) document.body.classList.remove(...withModalClassNames);
      }
    };
  }, []);

  const pointerEvents = presenceProps.value.to((val) => (val > 0.9 ? "initial" : "none"));

  return (
    <ClickOutsideProvider>
      {(outsideClickHandlers) => (
        <>
          <XCol
            as={animated.div}
            fixed
            inset="full"
            style={{opacity: presenceProps.value, pointerEvents}}
            className={cx(
              styles.backdrop.base,
              purpleBackdrop && styles.backdrop.purple,
              blueBackdrop && styles.backdrop.blue,
              blurBackdrop && styles.backdrop.blurBackdrop
            )}
            {...outsideClickHandlers}
          />
          <FocusLock group={focusGroupName} returnFocus>
            <focusLockKeyGroupContext.Provider value={focusGroupName}>
              <KeyPressContextProvider>
                <XCol
                  as={animated.div}
                  fixed
                  inset="full"
                  {...outsideClickHandlers}
                  style={{pointerEvents}}
                  className={styles.outer}
                >
                  <XCol
                    as={animated.div}
                    absolute
                    inset="full"
                    style={{
                      opacity: presenceProps.value,
                      transform: presenceProps.value.to(
                        (val) => `translateY(${-50 * (1 - val)}px)`
                      ),
                      pointerEvents,
                    }}
                    data-cdx-context={cdxContext}
                    className={cx(
                      styles.container.base,
                      fixedHeight && styles.container.fixedHeight
                    )}
                  >
                    <ClickOutside onClickOutside={onClose}>
                      {(handlers) => (
                        <XCol
                          className={cx(
                            styles.content.base,
                            fixedHeight && styles.content.fixedHeight
                          )}
                          elevation={2}
                          relative
                          rounded="sm"
                          bg={bg || "white"}
                          {...handlers}
                          style={width && {width}}
                        >
                          <Suspense fallback={<RawSpinner />}>
                            {!hideClose && (
                              <XCol absolute style={{top: 23, right: 23}}>
                                <XTextButton square onClick={onClose}>
                                  <Ui.Icon.Close size={18} />
                                </XTextButton>
                              </XCol>
                            )}
                            {children}
                          </Suspense>
                        </XCol>
                      )}
                    </ClickOutside>
                  </XCol>
                </XCol>
              </KeyPressContextProvider>
            </focusLockKeyGroupContext.Provider>
          </FocusLock>
        </>
      )}
    </ClickOutsideProvider>
  );
};

/**
 * @template T
 * @typedef {Object} ModalProps
 * @property {() => void} onClose
 * @property {boolean} hideClose
 * @property {number} width
 * @property {string=} cdxContext
 * @property {boolean=} purpleBackdrop
 * @property {boolean=} blurBackdrop
 * @property {boolean=} blueBackdrop
 * @property {(item: T) => string | number=} extractKey
 * @property {string=} bg
 */

/**
 * @template T
 * @param {T} data
 * @param {ModalProps<T>} props
 *
 */
export const useModalWithData = (
  data,
  {
    onClose,
    hideClose,
    width,
    cdxContext,
    blueBackdrop,
    purpleBackdrop,
    blurBackdrop,
    extractKey = (i) => i,
    bg,
  }
) => {
  const transition = useTransition(data, {
    key: extractKey,
    from: {value: 0},
    enter: {value: 1},
    leave: {value: 0.001},
    config: springConfigs.quickNoBounce,
  });
  return (renderFn) =>
    transition((props, item) =>
      item ? (
        <Portal>
          <Modal
            presenceProps={props}
            onClose={onClose}
            hideClose={hideClose}
            width={item.getWidth ? item.getWidth() : width}
            cdxContext={cdxContext}
            bg={item.getBg ? item.getBg() : bg}
            fixedHeight={item.getFixedHeight && item.getFixedHeight()}
            blueBackdrop={blueBackdrop}
            purpleBackdrop={purpleBackdrop}
            blurBackdrop={blurBackdrop}
          >
            {renderFn(item)}
          </Modal>
        </Portal>
      ) : null
    );
};

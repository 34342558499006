export const getZIndex = (node: HTMLElement | SVGElement) => {
  if (!node) return;
  // svgs don't have an offsetParent!
  let currentNode: HTMLElement | null =
    node.tagName === "svg" ? (node.parentNode as HTMLElement) : (node as HTMLElement);
  let lastZIndex = 0;
  while (currentNode?.offsetParent) {
    currentNode = currentNode.offsetParent as HTMLElement;
    const zIndexAsString = window.getComputedStyle(currentNode).getPropertyValue("z-index");
    if (zIndexAsString !== "auto") {
      lastZIndex = parseInt(window.getComputedStyle(currentNode).getPropertyValue("z-index"), 10);
    }
  }
  return lastZIndex;
};

import {lazy} from "react";
import qs from "qs";
import {pronounceSafeSeq} from "./lib/sequences";
import {api} from "./lib/api";
import {waitForResultPromise} from "./lib/wait-for-result";
import {slugify} from "./lib/utils";

const Decks = lazy(() => import(/* webpackChunkName: "Decks" */ "./pages/decks"));
const Milestone = lazy(() => import(/* webpackChunkName: "Milestones" */ "./pages/milestones"));

const cardSeqRegex = new RegExp(`^([${pronounceSafeSeq.letters}]{3,5})(?:-.*)?`);
const toCardPath = ({title, accountSeq}) =>
  `${pronounceSafeSeq.intToSeq(accountSeq)}-${slugify(title)}`;
export const cardPathToSeq = (cardPath) => {
  const m = cardPath.match(cardSeqRegex);
  if (m) return pronounceSafeSeq.seqToInt(m[1]);
  return null;
};

const deckSeqRegex = /^([\d]{1,5})(?:-.*)?/;
const toDeckPath = ({title, accountSeq}) => `${accountSeq}-${slugify(title)}`;
export const deckPathToSeq = (deckPath) => {
  const m = deckPath.match(deckSeqRegex);
  if (m) return parseInt(m[1], 10);
  return null;
};

const routes = {
  login: {
    noDefaultLayout: true,
    isPublic: true,
    path: "/login",
    getUrl: () => "/login",
    component: lazy(() => import(/* webpackChunkName: "Login" */ "./pages/login")),
  },
  notAuthorised: {
    isPublic: true,
    path: "/forbidden",
    getUrl: (reason) => `/forbidden${reason ? `?${qs.stringify({reason})}` : ""}`,
    component: lazy(() => import(/* webpackChunkName: "Forbidden" */ "./pages/403")),
  },
  freeLimitsExceeded: {
    isPublic: true,
    path: "/read-only",
    getUrl: () => "/read-only",
    component: lazy(() => import(/* webpackChunkName: "NoFunds" */ "./pages/402")),
  },

  join: {
    noDefaultLayout: true,
    isPublic: true,
    path: "/join/:token",
    getUrl: (token) => `/join/${token}`,
    component: lazy(() => import(/* webpackChunkName: "Join" */ "./pages/join")),
  },
  joinViaInviteCode: {
    noDefaultLayout: true,
    isPublic: true,
    path: "/join-link/:token",
    getUrl: (token) => `/join-link/${token}`,
    component: lazy(() => import(/* webpackChunkName: "Join" */ "./pages/join-via-invite-code")),
  },
  resetPassword: {
    noDefaultLayout: true,
    isPublic: true,
    path: "/reset-password/:token",
    getUrl: (token) => `/reset-password/${token}`,
    component: lazy(() => import(/* webpackChunkName: "ResetPassword" */ "./pages/reset-password")),
  },
  verifyEmail: {
    noDefaultLayout: true,
    isPublic: true,
    path: "/verify-email/:token",
    getUrl: (token) => `/verify-email/${token}`,
    component: lazy(() => import(/* webpackChunkName: "VerifyEmail" */ "./pages/verify-email")),
  },

  releases: {
    isPublic: true,
    path: "/changelog",
    getUrl: () => `/changelog`,
    component: lazy(
      () => import(/* webpackChunkName: "Releases" */ "./features/releases/ReleasePage")
    ),
  },
  error: {
    isPublic: true,
    path: "/error",
    getUrl: () => `/error`,
    component: lazy(() => import(/* webpackChunkName: "Error" */ "./pages/error")),
  },

  legacyProjectRoutes: {
    noDefaultLayout: true,
    path: "/p",
    getUrl: () => {
      throw new Error("Deprecated");
    },
    component: lazy(
      () =>
        import(/* webpackChunkName: "LegacyProjectRoutes" */ "./pages/legacy/legacy-project-routes")
    ),
  },

  hand: {
    noDefaultLayout: true,
    exact: true,
    path: ["", "/", "/card/:cardId", "/metrics", "/activity", "/upvotes"],
    getUrl: () => "",
    component: lazy(
      () => import(/* webpackChunkName: "HandQueue" */ "./features/hand-queue/HandQueueDashboard")
    ),
  },
  handWithCard: {
    doNotRender: true,
    path: "/card/:cardId",
    getUrl: ({accountSeq, title}) => `/card/${toCardPath({accountSeq, title})}`,
  },
  handWithMetrics: {
    doNotRender: true,
    path: "/metrics",
    getUrl: () => "/metrics",
  },

  decks: {
    noDefaultLayout: true,
    path: "/decks",
    getUrl: () => "/decks",
    component: Decks,
  },
  deck: {
    doNotRender: true,
    path: "/decks/:deckId",
    getUrl: ({accountSeq, title}) => `/decks/${toDeckPath({accountSeq, title})}`,
    ensureUrl: (deckId) =>
      waitForResultPromise(() => {
        const {title, accountSeq} = api.getModel({modelName: "deck", id: deckId});
        return {title, accountSeq};
      }).then(({title, accountSeq}) => `/decks/${toDeckPath({title, accountSeq})}`),
  },
  deckWithCard: {
    doNotRender: true,
    path: "/decks/:deckId/card/:cardId",
    getUrl: ({accountSeq, title}, {accountSeq: cSeq, title: cTitle}) =>
      `/decks/${toDeckPath({accountSeq, title})}/card/${toCardPath({
        accountSeq: cSeq,
        title: cTitle,
      })}`,
  },
  deckWorkflow: {
    doNotRender: true,
    path: "/decks/:deckId/journey",
    getUrl: ({accountSeq, title}) => `/decks/${toDeckPath({accountSeq, title})}/journey`,
  },
  deckWorkflowWithItem: {
    doNotRender: true,
    path: "/decks/:deckId/journey/step/:itemId",
    getUrl: ({accountSeq, title}, {accountSeq: sSeq, title: sStep}) =>
      `/decks/${toDeckPath({accountSeq, title})}/journey/step/${toCardPath({
        accountSeq: sSeq,
        title: sStep,
      })}`,
  },

  calendar: {
    noDefaultLayout: true,
    path: "/milestones/calendar",
    getUrl: () => "/milestones/calendar",
    component: lazy(
      () => import(/* webpackChunkName: "CalendarPage" */ "./pages/milestones/CalendarPage")
    ),
  },

  milestones: {
    noDefaultLayout: true,
    path: "/milestones",
    getUrl: () => "/milestones",
    component: Milestone,
  },

  // make sure future routes are also considered in 'useRouteDependentCardLink'
  dueDate: {
    doNotRender: true,
    path: "/milestones/due/:dayKey",
    getUrl: (dayKey) => `/milestones/due/${dayKey}`,
  },
  dueDateWithCard: {
    doNotRender: true,
    path: "/milestones/due/:dayKey/card/:cardId",
    getUrl: (dayKey, {accountSeq, title}) =>
      `/milestones/due/${dayKey}/card/${toCardPath({accountSeq, title})}`,
  },

  milestone: {
    doNotRender: true,
    path: "/milestones/:milestoneId",
    getUrl: (milestoneAccountSeq) => `/milestones/${milestoneAccountSeq}`,
  },
  milestoneWithCard: {
    doNotRender: true,
    path: "/milestones/:milestoneId/card/:cardId",
    getUrl: (milestoneAccountSeq, {accountSeq, title}) =>
      `/milestones/${milestoneAccountSeq}/card/${toCardPath({accountSeq, title})}`,
  },

  sprint: {
    doNotRender: true,
    path: "/milestones/level/:sprintId",
    getUrl: (sprintAccountSeq) => `/milestones/level/${sprintAccountSeq}`,
  },
  sprintWithCard: {
    doNotRender: true,
    path: "/milestones/level/:sprintId/card/:cardId",
    getUrl: (sprintAccountSeq, {accountSeq, title}) =>
      `/milestones/level/${sprintAccountSeq}/card/${toCardPath({accountSeq, title})}`,
  },

  media: {
    path: "/media",
    getUrl: () => "/media",
    component: lazy(() => import(/* webpackChunkName: "MediaLibray" */ "./pages/media-library")),
  },
  timeTrackingReport: {
    path: "/time-tracking-report",
    getUrl: () => "/time-tracking-report",
    component: lazy(
      () =>
        import(/* webpackChunkName: "TimeTracking" */ "./features/time-tracking/TimeTrackingReport")
    ),
  },

  visionBoard: {
    noDefaultLayout: true,
    path: "/vision-board",
    getUrl: () => "/vision-board",
    component: lazy(
      () => import(/* webpackChunkName: "VisionBoard" */ "./features/vision-board/VisionBoardHub")
    ),
  },

  // legacy, still maintained to ensure unsubscription links still work
  profile: {
    path: "/profile",
    getUrl: () => "/profile",
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "UserProfile" */ "./features/user-profile/LegacyPageWithRedirect"
        )
    ),
  },
};

export default routes;

import {useMemo} from "react";
import qs from "qs";
import {useModalWithData} from "./Modals";
import {Router, useLocation} from "react-router-dom";
import memoizeOne from "memoize-one";

export const MODAL_QUERY_PARAM = "show";

const dropLeadingSlash = (s) => (s[0] === "/" ? s.slice(1) : s);

const getModalName = memoizeOne((search) => {
  const query = qs.parse(search, {ignoreQueryPrefix: true});
  const modalPath = query[MODAL_QUERY_PARAM];
  if (!modalPath) return null;
  const m = modalPath.match(/^([^/.]+)([/.].*)/);
  return m
    ? {modalName: m[1], innerPath: m[2], query}
    : {modalName: modalPath, innerPath: "/", query};
});

const transformLocation = (location) => {
  const res = getModalName(location.search);
  return res
    ? {
        pathname: res.innerPath.replace(/\./g, "/") || "/",
        search: "",
        hash: "",
        state: {...location.state?.modalState, $withinModal: true},
      }
    : {pathname: "/", search: "", hash: "", state: null};
};

const getModalHistory = ({modalName, history: orgHistory}) => {
  let passedLocation = orgHistory.location;
  const toModalLocation = (passedPath, passedState) => {
    if (typeof passedPath === "string") {
      if (passedPath.match(/^\/?:root:/)) {
        const rootPath = passedPath.replace(/^\/?:root:/, "");
        return passedState ? [rootPath, passedState] : [rootPath];
      }
    } else if (passedPath.search) {
      return [passedPath, passedState];
    }
    const query = qs.parse(passedLocation.search, {ignoreQueryPrefix: true});
    const pathPart = `${modalName}/${dropLeadingSlash(passedPath)}`;
    const path = `${passedLocation.pathname}?${qs.stringify({
      ...query,
      [MODAL_QUERY_PARAM]: pathPart.replace(/\//g, "."),
    })}`;
    return passedState ? [path, {modalState: passedState}] : [path];
  };

  const wrapper = {
    get(target, key) {
      const orgVal = target[key];
      switch (key) {
        case "location":
          return transformLocation(passedLocation);
        case "push":
          return (path, state) => orgVal(...toModalLocation(path, state));
        case "replace":
          return (path, state) => orgVal(...toModalLocation(path, state));
        case "createHref":
          return (location) =>
            location.search ? orgVal(location) : toModalLocation(location.pathname)[0];
        case "listen":
          return (fn) => orgVal((nextLocation) => fn(transformLocation(nextLocation)));
        default:
          return orgVal;
      }
    },
  };

  const wrapped = new Proxy(orgHistory, wrapper);
  return {
    withLocation(location) {
      passedLocation = location;
      return wrapped;
    },
  };
};

const ModalRegistry = ({history, location, root, modals}) => {
  const {modalName} = getModalName(location.search) || {};

  const {comp: ModalComp, defaultProps} = modals[modalName] || {};
  const modalHistory = useMemo(
    () => (ModalComp && modalName ? getModalHistory({modalName, history}) : null),
    [ModalComp, modalName, history]
  );

  const onClose = () => {
    history.push({
      ...location,
      search: qs.stringify({
        [MODAL_QUERY_PARAM]: undefined,
      }),
    });
  };

  const renderModal = useModalWithData(
    ModalComp && {
      comp: ModalComp,

      getWidth: () => defaultProps?.width,
      getBg: () => defaultProps?.bg,
      getFixedHeight: () => defaultProps?.fixedHeight,
      modalHistory: modalHistory.withLocation(location),
    },
    {
      onClose,
      hideClose: defaultProps?.hideClose,
      width: defaultProps?.width,
      cdxContext: modalName,
      extractKey: (data) => data && data.content,
    }
  );
  return renderModal(({comp: Comp, state, modalHistory: currHistory}) => (
    <Router history={currHistory}>
      <Comp
        root={root}
        onClose={onClose}
        history={currHistory}
        modalState={state}
        location={currHistory.location}
      />
    </Router>
  ));
};
export const ModalAddress = ({modal, children}) => children(useModalAdress({modal}));

export const getModalUrl = ({modal, location, state}) => ({
  ...location,
  search: qs.stringify({
    ...qs.parse(location.search, {ignoreQueryPrefix: true}),
    [MODAL_QUERY_PARAM]: modal,
  }),
  state: {...location.state, modalState: state},
});

export const useModalAdress = ({modal, state}) => {
  const location = useLocation();
  return getModalUrl({modal, location, state});
};

export default ModalRegistry;

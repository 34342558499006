import {
  useState,
  useRef,
  useEffect,
  useMemo,
  cloneElement,
  ReactNode,
  CSSProperties,
  ReactElement,
} from "react";
import {overlayStyles} from "./overlay.css";
import {delayedTrigger} from "../delayed-trigger";
import {ArrowOverlay, OverlayPlacer as OriginalOverlayPlacer} from ".";
import cx from "../cx";
import Portal from "../Portal";
import {springConfigs, useReveal} from "../hooks/useReveal";
import {forwardRef} from "react";

const OverlayPlacer = OriginalOverlayPlacer as any;

const checkIfRef = (obj: any) =>
  obj !== null && typeof obj === "object" && obj.hasOwnProperty("current");

export const useGetNodeRefFromRef = (maybeRef: any) => {
  const innerRef = useRef();
  const isRef = checkIfRef(maybeRef);

  return {
    nodeRef: isRef ? maybeRef : innerRef,
    ref: useMemo(() => {
      if (isRef) return maybeRef;
      if (typeof maybeRef === "function") {
        return (nextNode: any) => {
          innerRef.current = nextNode;
          maybeRef(nextNode);
        };
      }
      return innerRef;
    }, [isRef, maybeRef]),
  };
};

export const useGetNodeFromRef = (maybeRef: any) => {
  const [innerNode, setInnerNode] = useState(null);
  const isRef = checkIfRef(maybeRef);
  const node = isRef ? maybeRef.current : innerNode;

  useEffect(() => {
    if (isRef) {
      setInnerNode(() => maybeRef.current || null);
      return () => setInnerNode(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRef]);

  return {
    node,
    ref: useMemo(() => {
      if (isRef) return maybeRef;
      if (typeof maybeRef === "function")
        return (nextNode: any) => {
          setInnerNode(nextNode);
          maybeRef(nextNode);
        };
      return setInnerNode;
    }, [isRef, maybeRef]),
  };
};

// if I waited for a delayed tooltip, all other delayed tooltips should show up immediately!
let isInReadingMood = false;
const killReadingMoodTrigger = delayedTrigger();

type TooltipContent = string | ReactNode | (() => ReactNode);
type TooltipOptions = Partial<{
  hidden: boolean;
  forceOpen: boolean;
  delayed: boolean;
  delayMs: number;
  className: string;
  placement: "top" | "left" | "bottom" | "right";
  bg: string;
  targetIsDisabled: boolean;
  distanceFromAnchor: number;
  style: CSSProperties;
  arrowUsesBgVar: string;
  outerClassName: string;
}>;

export const useTooltip = ({
  node,
  hidden,
  forceOpen,
  delayed,
  delayMs = delayed ? 500 : 50,
  content,
  className,
  placement = "top",
  bg = "active",
  targetIsDisabled,
  distanceFromAnchor = 5,
  style: userStyle = {},
  arrowUsesBgVar,
  outerClassName,
}: TooltipOptions & {node: HTMLElement; content: TooltipContent}) => {
  const [open, setOpen] = useState(false);

  const delayMsRef = useRef(delayMs);
  useEffect(() => {
    delayMsRef.current = delayMs;
  }, [delayMs]);
  const nodeRef = useRef(node);
  useEffect(() => {
    nodeRef.current = node;
  }, [node]);

  const eventsRef = useRef<undefined | any>();
  if (!eventsRef.current) {
    const trigger = delayedTrigger();
    const killIt = () => {
      trigger.cancel();
      setOpen(false);
      killReadingMoodTrigger.fire(() => {
        isInReadingMood = false;
      }, 500);
    };
    eventsRef.current = {
      events: {
        onMouseEnter: () => {
          if (isInReadingMood) {
            setOpen(true);
            killReadingMoodTrigger.cancel();
          } else {
            trigger.fire(() => {
              setOpen(true);
              if (delayed) isInReadingMood = true;
            }, delayMsRef.current);
          }
        },
        onMouseLeave: killIt,
      },
    };
  }

  const shownContent = !hidden && (open || forceOpen) && content;

  useEffect(() => {
    if (open) {
      const handleMouseMove = (e: MouseEvent) => {
        if (!nodeRef.current) return;
        const isIn = nodeRef.current.contains(e.target as Node);
        if (!isIn) eventsRef.current.events.onMouseLeave();
      };
      document.addEventListener("mousemove", handleMouseMove);
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, [open]);

  useEffect(() => {
    if (targetIsDisabled) {
      const handleMouseMove = (e: MouseEvent) => {
        if (!nodeRef.current) return;
        const isIn = nodeRef.current.contains(e.target as Node);
        if (!open && isIn) {
          eventsRef.current.events.onMouseEnter();
        } else if (open && !isIn) {
          eventsRef.current.events.onMouseLeave();
        }
      };
      document.addEventListener("mousemove", handleMouseMove);
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, [targetIsDisabled, open]);

  const reveal = useReveal(shownContent, {config: springConfigs.quick} as any);
  const tooltip = reveal((props: any, revealContent: any) => {
    const realContent = typeof revealContent === "function" ? revealContent() : revealContent;
    const isString = typeof realContent === "string";
    const renderOverlay = (overProps: any) => (
      <ArrowOverlay
        {...overProps}
        bg={bg}
        arrowSize="xs"
        contentStyle={{
          ...(isString && {
            maxWidth: Math.min(275, (userStyle.maxWidth as number) || Infinity),
            textAlign: "center",
          }),
          ...userStyle,
        }}
        noPointerEvents
        arrowUsesBgVar={arrowUsesBgVar}
        className={cx(
          overlayStyles.tooltip.base,
          isString && overlayStyles.tooltip.isString,
          className
        )}
        outerClassName={outerClassName}
      >
        {realContent}
      </ArrowOverlay>
    );
    return (
      <Portal>
        <OverlayPlacer
          isOpen
          distanceFromAnchor={distanceFromAnchor}
          node={node}
          presenceProps={props}
          renderOverlay={renderOverlay}
          placement={placement}
        />
      </Portal>
    );
  });

  return {events: eventsRef.current.events, tooltipElement: tooltip};
};

export const WithTooltip = forwardRef<
  HTMLElement,
  {tooltip: TooltipContent; options: TooltipOptions; passedProps: any; as: any}
>(({as: Comp = "div", tooltip, options, passedProps, ...rest}, passedRef) => {
  const {node, ref} = useGetNodeFromRef(passedRef);
  const {events, tooltipElement} = useTooltip({
    node,
    content: tooltip,
    ...options,
  });
  return (
    <>
      {tooltipElement}
      <Comp {...rest} {...passedProps} {...events} ref={ref} />
    </>
  );
});

export const TooltipForChild = forwardRef<
  HTMLElement,
  {
    tooltip: TooltipContent;
    children: ReactElement;
    className?: string;
    tooltipClassName?: TooltipOptions["className"];
  } & Omit<TooltipOptions, "className">
>(({tooltip, children, className, tooltipClassName, ...options}, passedRef) => {
  const {
    hidden,
    forceOpen,
    delayed,
    delayMs,
    placement,
    bg,
    targetIsDisabled,
    distanceFromAnchor,
    style,
    arrowUsesBgVar,
    outerClassName,
    ...rest
  } = options;
  const {node, ref} = useGetNodeFromRef(passedRef);
  const {events, tooltipElement} = useTooltip({
    node,
    content: tooltip,
    hidden,
    forceOpen,
    delayed,
    delayMs,
    placement,
    bg,
    targetIsDisabled,
    distanceFromAnchor,
    style,
    arrowUsesBgVar,
    outerClassName,
    className: tooltipClassName,
  });
  if (process.env.NODE_ENV !== "production") {
    if ("ref" in children?.props) {
      throw new Error(
        "Do not pass refs to children of <TooltipForChild/>. Pass it to the Tooltip directly"
      );
    }
  }
  return (
    <>
      {tooltipElement}
      {cloneElement(children, {
        ...events,
        ref,
        ...(className && {className: cx(className, children.props.className)}),
        ...rest,
      })}
    </>
  );
});

import {useState, useEffect} from "react";
import PushUpdates from "./PushUpdates"; // consider moving this and others into e.g. Root/PushUpdates
import ShortcutLister from "./ShortcutLister";
import messenger, {MessengerArea} from "../lib/messenger";
import {exceptionEvent} from "../lib/error-logger";
import ModalRegistry from "./ModalRegistry";
import {ConfirmationRegistry} from "../lib/confirm";
import {SetupHints} from "../features/hints/HintManager";
import "@cdx/common/fonts/fonts.css";
import "@fontsource-variable/roboto-mono";
import "modern-normalize/modern-normalize.css";
import "../global-styles.css";
import TimerWidget from "../features/time-tracking/TimerWidget";
import {BlingProvider} from "../lib/hooks/useBling";
import Ui from "./ui2";
import {XCol, XText} from "./xui";
import XTextButton from "./xui/XTextButton";
import {ClickOutsideProvider, KeyPressContextProvider, cx, errorToString} from "@cdx/common";
import UiHandlerContext from "@cdx/ds/components/DSForm/UiHandlerCtx";
import {ApiErrors} from "./ErrorOverlays";
import {useLocation, useHistory} from "react-router-dom";
import {useRoot} from "../lib/mate/mate-utils";
import {useSetTitle} from "./Title";
import {DeploymentInfo} from "./DeploymentInfo";
import {API_ERRORS} from "../lib/request";
import ReleaseAlerter from "../features/releases/ReleaseAlerter";
import useSyncTimezone from "../lib/hooks/useSyncTimezone";
import {apiErrorEvent} from "../lib/api-error-event";
import {rootStatusColorOverwrites} from "@cdx/ds/components/DSCard/DSCardTheme.css";
import dsStyles from "@cdx/ds/css/index.css";
import cdxEnv from "../env";

const uiHandlers = {
  onButtonError: (error) => {
    console.error("button click resulted in ", error);
    messenger.send(errorToString(error), {type: "error", msToRemove: 10000});
  },
};

const ErrorPill = ({onClose}) => (
  <XCol
    fixed
    style={{bottom: 10, left: "50%", width: 250, marginLeft: -125, zIndex: 3}}
    rounded="sm"
    px={4}
    py={3}
    bg="error200"
    elevation={2}
  >
    <XCol absolute style={{top: 15, right: 15}}>
      <XTextButton square onClick={onClose}>
        <Ui.Icon.Close size={12} />
      </XTextButton>
    </XCol>
    <XText size={1} color="error800">
      Something just broke :(
      <br />
      You might need to reload this page.
    </XText>
  </XCol>
);

const Root = ({children, notFound = "404", modals}) => {
  const root = useRoot();
  const location = useLocation();
  const history = useHistory();
  const [got404, set404] = useState();
  const [showExceptionThrown, setShowExceptionThrown] = useState(false);
  useSyncTimezone(root);

  useEffect(() => {
    return exceptionEvent.addListener(() => setShowExceptionThrown(true));
  }, []);

  useEffect(() =>
    apiErrorEvent.addListener((e) => {
      if (e && e.type === API_ERRORS.API_ERROR && e.status === 404) {
        set404(true);
      }
    })
  );

  useSetTitle(
    process.env.REACT_APP_MODE !== "open" && root.account && root.account.$meta.get("name", null),
    {pos: 3}
  );

  const statusColorPalette = root.loggedInUser?.$meta.get("statusColorPalette", "default");
  const paletteClassName =
    statusColorPalette !== "default" ? rootStatusColorOverwrites[statusColorPalette] ?? null : null;

  useEffect(() => {
    if (paletteClassName) {
      document.body.classList.add(paletteClassName);
    }
    return () => document.body.classList.remove(paletteClassName);
  }, [paletteClassName]);

  if (got404 && (!root.account || !root.account.$meta.isLoaded)) {
    return notFound;
  }

  if (!root.account) {
    return (
      <XCol pa={3} sp={1}>
        <XText>Organisation not found.</XText>
        <XText>
          If this problem persists please get in touch with hello@codecks.io and tell us what
          happened to get here!
        </XText>
      </XCol>
    );
  }

  return (
    <UiHandlerContext.Provider value={uiHandlers}>
      <KeyPressContextProvider>
        <ClickOutsideProvider>
          {(handlers) => (
            <div
              className={cx(
                dsStyles.position.relative,
                dsStyles.zIndex[0],
                dsStyles.minHeight["100%"]
              )}
              {...handlers}
            >
              {children}
              <ModalRegistry location={location} history={history} root={root} modals={modals} />
              <PushUpdates root={root} />
              <ShortcutLister />
              <MessengerArea />
              <ConfirmationRegistry />
              <DeploymentInfo />
              {cdxEnv.ON_PREMISE !== "true" && (
                <ReleaseAlerter root={root} location={location} history={history} />
              )}
              {process.env.REACT_APP_MODE !== "open" && <TimerWidget root={root} />}
              <BlingProvider />
              <ApiErrors history={history} location={location} />
              {showExceptionThrown && <ErrorPill onClose={() => setShowExceptionThrown(false)} />}
              <SetupHints root={root} />
            </div>
          )}
        </ClickOutsideProvider>
      </KeyPressContextProvider>
    </UiHandlerContext.Provider>
  );
};

export default Root;

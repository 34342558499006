import {ComponentProps, ReactNode, forwardRef, useContext, useRef, useState} from "react";
import {animated, useSpring, useTransition} from "react-spring";
import cx from "../../utils/cx";
import {Box, css, Row, StyleProps} from "../Box/Box";
import {dsButtonStyles as styles} from "./DSButton.css";
import {Link} from "react-router-dom";
import DSSpinner from "../DSIcon/DSSpinner";
import {DSIconCheck, DSIconClose} from "../DSIcon/DSIcon";
import UiHandlerContext from "../DSForm/UiHandlerCtx";
import {isWithMetaKey} from "../../utils/device-utils";

type ButtonStyles = typeof styles;

// eslint-disable-next-line no-shadow
type ButtonState = "initial" | "loading" | "success" | "error";

type FullProps<T> =
  | (Omit<JSX.IntrinsicElements["button"], keyof T> & T & {href?: undefined; to?: undefined})
  | (Omit<JSX.IntrinsicElements["a"], keyof T> &
      T & {href: string; to?: undefined; disabled?: boolean})
  | (Omit<ComponentProps<Link>, keyof T> &
      T & {href?: undefined; to: ComponentProps<Link>["to"]; disabled?: boolean});

export type DsButtonAction =
  | {
      href?: undefined;
      to?: undefined;
      onClick: JSX.IntrinsicElements["button"]["onClick"];
      onMetaClick?: JSX.IntrinsicElements["button"]["onClick"];
    }
  | {href: string; to?: undefined; disabled?: boolean}
  | {href?: undefined; to: ComponentProps<Link>["to"]; disabled?: boolean};

export type SelfDSRawButtonProps = {
  variant?: keyof ButtonStyles["variantVars"] | null;
  size?: keyof ButtonStyles["sizes"] | null;
  customSize?: keyof ButtonStyles["customSizes"] | null;
  contentPadding?: null | "both" | "left" | "right";
  iconColor?: null | "primary" | "secondary";
  active?: boolean;
  state?: ButtonState;
  negatePadding?: boolean;
  onMetaClick?: React.MouseEventHandler<HTMLButtonElement>;
  theme?: "success" | "error";
};
export type FullSelfDSRawButtonProps = FullProps<SelfDSRawButtonProps>;
export const DSRawButton = forwardRef<HTMLElement, FullSelfDSRawButtonProps>((props, ref) => {
  const {
    variant = "primary",
    size = "md",
    customSize,
    contentPadding = "both",
    className,
    iconColor = "secondary",
    active,
    state,
    disabled,
    negatePadding,
    to,
    theme,
    ...rest
  } = props;
  const classes = cx(
    styles.base,
    variant && styles.variantVars[variant],
    variant && styles.variantStyles[variant],
    variant && active && styles.activeVariants[variant],
    !customSize && size && styles.sizes[size],
    customSize && styles.customSizes[customSize],
    state === "loading" && styles.loadingState,
    variant && theme === "success" && styles.successVariants[variant],
    variant && theme === "error" && styles.errorVariants[variant],
    variant && !theme && state === "success" && styles.successVariants[variant],
    variant && !theme && state === "error" && styles.errorVariants[variant],
    contentPadding && styles.contentPaddings[contentPadding],
    negatePadding && styles.negatePadding,
    (to || rest.href) && styles.isLink,
    (to || rest.href) && disabled && styles.isLinkDisabled,
    iconColor && styles.iconColors[iconColor],
    className
  );
  if (to) {
    return (
      <Link
        className={classes}
        {...(rest as any)}
        {...(disabled ? {onClick: (e) => e.preventDefault()} : {})}
        to={disabled ? "." : to}
        ref={ref as any}
      />
    );
  } else if (rest.href) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        className={classes}
        target="_blank"
        rel="noopener noreferrer"
        {...rest}
        {...(disabled ? {onClick: (e) => e.preventDefault()} : {})}
        ref={ref as any}
      />
    );
  } else {
    return (
      <button
        className={classes}
        type="button"
        disabled={disabled || state === "loading"}
        {...(rest as any)}
        ref={ref}
      />
    );
  }
});

type SelfDSButtonProps = Omit<SelfDSRawButtonProps, "contentPadding">;

export const DSButton = forwardRef<HTMLElement, FullProps<SelfDSButtonProps>>((props, ref) => {
  const {onClick, onMetaClick, children, state: passedState, ...rest} = props;
  const {handleClick, icons, state} = usePending({
    onClick: props.onClick,
    onMetaClick,
    passedState,
  });

  const contentStyles = useSpring({
    x: state === "initial" ? "0%" : "100%",
    scale: state === "initial" ? 1 : 0.8,
    opacity: state === "initial" ? 1 : 0,
    config: {tension: 600, friction: 44},
  });

  return (
    <DSRawButton state={state} {...rest} onClick={handleClick} ref={ref}>
      {icons}
      <animated.div style={contentStyles}>{children}</animated.div>
    </DSRawButton>
  );
});

type SelfDSIconButtonProps = Omit<SelfDSRawButtonProps, "contentPadding"> & {
  icon: ReactNode;
  iconPosition?: "left" | "right";
  label?: string | null;
  children?: undefined;
  sp?: StyleProps["sp"];
};

const FadeIcon = ({icon, shown}: {shown: boolean; icon: ReactNode}) => {
  const from = {opacity: 0, scale: 0.25, y: "-20%"};
  const enter = {opacity: 1, scale: 1, y: "0%"};
  const renderFn = useTransition(shown, {
    from: from,
    enter: enter,
    leave: from,
    config: {tension: 600, friction: 44},
  });
  return renderFn((iconStyles, item) =>
    item ? (
      <animated.div
        className={css({
          display: "flex",
          flexDir: "column",
          align: "center",
          justify: "center",
          position: "absolute",
          inset: "0",
        })}
        style={iconStyles}
      >
        {icon}
      </animated.div>
    ) : null
  );
};

type PendingOpts = Pick<FullProps<SelfDSIconButtonProps>, "onClick" | "onMetaClick"> & {
  passedState?: ButtonState;
};

const usePending = (opts: PendingOpts) => {
  const [innerState, setIsPending] = useState<ButtonState>("initial");
  const state = opts.passedState || innerState;
  const currClickRef = useRef({});
  const {onButtonError} = useContext(UiHandlerContext);
  const handleClick = (e?: any) => {
    const clickFn = opts.onMetaClick && isWithMetaKey(e) ? opts.onMetaClick : opts.onClick;
    if (!clickFn) return;
    const res = clickFn(e as any) as any as Promise<unknown>;
    if (typeof res?.then === "function") {
      const currClickObj = (currClickRef.current = {});
      setIsPending("loading");
      const setter = (val: ButtonState) => {
        if (currClickRef.current === currClickObj) setIsPending(val);
      };
      res.then(
        () => {
          setter("success");
          setTimeout(() => {
            setter("initial");
          }, 350);
        },
        (error: any) => {
          setter("error");
          setTimeout(() => {
            setter("initial");
          }, 2000);
          onButtonError(error);
        }
      );
    }
  };
  return {
    handleClick,
    state,
    icons: (
      <>
        <FadeIcon shown={state === "loading"} icon={<DSSpinner />} />
        <FadeIcon shown={state === "success"} icon={<DSIconCheck />} />
        <FadeIcon shown={state === "error"} icon={<DSIconClose />} />
      </>
    ),
  };
};

export const usePendingClick = usePending;

export const useReactiveIcon = ({
  onClick,
  onMetaClick,
  iconEl,
  passedState,
}: {
  iconEl: ReactNode;
  onClick: any;
  onMetaClick?: any;
  passedState?: ButtonState;
}) => {
  const {handleClick, icons, state} = usePending({
    onClick,
    onMetaClick,
    passedState,
  });
  const iconStyles = useSpring({
    y: state === "initial" ? "0%" : "50%",
    scale: state === "initial" ? 1 : 0.25,
    opacity: state === "initial" ? 1 : 0,
    config: {tension: 600, friction: 44},
  });
  const reactiveIcon = (
    <Box relative>
      {icons}
      <animated.div style={iconStyles}>{iconEl}</animated.div>
    </Box>
  );
  return {reactiveIcon, handleClick, state};
};

export const DSIconButton = forwardRef<HTMLElement, FullProps<SelfDSIconButtonProps>>(
  (props, ref) => {
    const {
      icon,
      iconPosition = "left",
      label = null,
      sp = "4px",
      state: passedState,
      onClick,
      onMetaClick,
      ...rest
    } = props;
    const {handleClick, reactiveIcon, state} = useReactiveIcon({
      iconEl: icon,
      onClick,
      onMetaClick,
      passedState,
    });

    return (
      <DSRawButton
        {...rest}
        contentPadding={label ? (iconPosition === "left" ? "right" : "left") : null}
        iconColor={label ? "secondary" : "primary"}
        onClick={handleClick}
        state={state}
        ref={ref}
      >
        {label ? (
          <Row sp={sp} align="center">
            {iconPosition === "left" && reactiveIcon}
            <span>{label}</span>
            {iconPosition === "right" && reactiveIcon}
          </Row>
        ) : (
          reactiveIcon
        )}
      </DSRawButton>
    );
  }
);

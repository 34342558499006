import {Suspense, lazy} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";

import {Spinner, XCol} from "@cdx/common";
import {useRoot, MateContextProvider} from "../../lib/mate/mate-utils";
import publicProjectRoutes from "./public-project-routes";
import {arenaStyles} from "../../layouts/arena-layout/arena.css";
import Root from "../../components/Root";
import {api} from "../../lib/api";

const Loader = () => (
  <XCol className={arenaStyles.outer} bg="gray700">
    <Spinner />
  </XCol>
);

const CdxRoute = ({component: RawComp, path, exact, root}) => (
  <Route
    path={path}
    exact={exact}
    render={(props) => {
      const compProps = {...props, params: props.match.params, root};
      return (
        <Suspense fallback={<Loader />}>
          <RawComp {...compProps} />
        </Suspense>
      );
    }}
  />
);

const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ "../../pages/404"));

const Routes = () => {
  const root = useRoot();

  return (
    <Switch>
      {Object.values(publicProjectRoutes)
        .filter((routeInfo) => !routeInfo.doNotRender)
        .map((routeInfo) => (
          <CdxRoute key={routeInfo.path} {...routeInfo} root={root} />
        ))}
      <Route
        render={(props) => (
          <Suspense fallback={<Loader />}>
            <NotFound {...props} />
          </Suspense>
        )}
      />
    </Switch>
  );
};

const modals = {
  userProfile: {
    comp: lazy(() => import("../user-profile/PublicProjectsProfile")),
    defaultProps: {fixedHeight: true},
  },
  attachmentViewer: {
    comp: lazy(() => import("../image-viewer/AttachmentViewer")),
    defaultProps: {width: "auto", hideClose: true},
  },
  imageViewer: {
    comp: lazy(() => import("../image-viewer/ImageViewer")),
    defaultProps: {width: "auto", hideClose: true},
  },
  commentHistory: {
    comp: lazy(() => import("../../pages/comment-history")),
    defaultProps: {width: 500},
  },
};

const OpenApp = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={publicProjectRoutes.portal.component} />
      <CdxRoute
        path="/card/:cardId"
        component={lazy(() => import(/* webpackChunkName: "PublicCard" */ "./PublicCard"))}
      />
      <Route
        render={() => (
          <MateContextProvider api={api}>
            <Root
              modals={modals}
              notFound={
                <Suspense fallback={<Spinner />}>
                  <NotFound />
                </Suspense>
              }
            >
              <Routes />
            </Root>
          </MateContextProvider>
        )}
      />
    </Switch>
  </BrowserRouter>
);

export default OpenApp;

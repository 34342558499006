import {FC, forwardRef} from "react";
import {ReactComponent as ArrowRightLongSvg} from "../../icons/arrow-right-long.svg";
import {ReactComponent as ArrowRightSvg} from "../../icons/arrow-right.svg";
import {ReactComponent as BlockSvg} from "../../icons/block.svg";
import {ReactComponent as CheckSvg} from "../../icons/check.svg";
import {ReactComponent as CloseSvg} from "../../icons/close.svg";
import {ReactComponent as CrownCollapseSvg} from "../../icons/crown-collapse.svg";
import {ReactComponent as CrownUncollapsedSvg} from "../../icons/crown-uncollapsed.svg";
import {ReactComponent as GlobeSvg} from "../../icons/globe.svg";
import {ReactComponent as HandSingleCardSvg} from "../../icons/hand-single-card.svg";
import {ReactComponent as HourglassFullSvg} from "../../icons/hourglass-full.svg";
import {ReactComponent as HourglassHalfSvg} from "../../icons/hourglass-half.svg";
import {ReactComponent as JoystickSvg} from "../../icons/joystick.svg";
import {ReactComponent as MinusSvg} from "../../icons/minus.svg";
import {ReactComponent as PlaySvg} from "../../icons/play.svg";
import {ReactComponent as PlusSvg} from "../../icons/plus.svg";
import {ReactComponent as ReviewSvg} from "../../icons/review.svg";
import {ReactComponent as SnoozingSvg} from "../../icons/snoozing.svg";
import {cx} from "@cdx/common";
import {legacyIconStyle} from "./legacy-ui2.css";
import {CSSProperties} from "@vanilla-extract/css";

type Props = {
  comp: FC<{}>;
  forwardedRef: any;
  fallbackAlt: string;
};

type IconProps = Partial<{alt?: string; size?: number; style: CSSProperties; className: string}>;

const Icon = (props: Props & IconProps) => {
  const {comp, forwardedRef, alt, fallbackAlt, size, style, className, ...rest} = props;
  const SvgComp = comp as any;
  return (
    <div
      style={{fontSize: size, ...style} as any}
      className={cx(className, legacyIconStyle)}
      ref={forwardedRef}
      {...rest}
    >
      <SvgComp alt={alt || fallbackAlt} />
    </div>
  );
};

const Icons = {
  ArrowRightLong: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={ArrowRightLongSvg} forwardedRef={ref} {...props} fallbackAlt="ArrowRightLong" />
  )),
  ArrowRight: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={ArrowRightSvg} forwardedRef={ref} {...props} fallbackAlt="ArrowRight" />
  )),
  Block: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={BlockSvg} forwardedRef={ref} {...props} fallbackAlt="Block" />
  )),
  Check: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={CheckSvg} forwardedRef={ref} {...props} fallbackAlt="Check" />
  )),
  Close: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={CloseSvg} forwardedRef={ref} {...props} fallbackAlt="Close" />
  )),
  CrownCollapse: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={CrownCollapseSvg} forwardedRef={ref} {...props} fallbackAlt="CrownCollapse" />
  )),
  CrownUncollapsed: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={CrownUncollapsedSvg} forwardedRef={ref} {...props} fallbackAlt="CrownUncollapsed" />
  )),
  Globe: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={GlobeSvg} forwardedRef={ref} {...props} fallbackAlt="Globe" />
  )),
  HandSingleCard: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={HandSingleCardSvg} forwardedRef={ref} {...props} fallbackAlt="HandSingleCard" />
  )),
  HourglassFull: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={HourglassFullSvg} forwardedRef={ref} {...props} fallbackAlt="HourglassFull" />
  )),
  HourglassHalf: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={HourglassHalfSvg} forwardedRef={ref} {...props} fallbackAlt="HourglassHalf" />
  )),
  Joystick: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={JoystickSvg} forwardedRef={ref} {...props} fallbackAlt="Joystick" />
  )),
  Minus: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={MinusSvg} forwardedRef={ref} {...props} fallbackAlt="Minus" />
  )),
  Play: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={PlaySvg} forwardedRef={ref} {...props} fallbackAlt="Play" />
  )),
  Plus: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={PlusSvg} forwardedRef={ref} {...props} fallbackAlt="Plus" />
  )),
  Review: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={ReviewSvg} forwardedRef={ref} {...props} fallbackAlt="Review" />
  )),
  Snoozing: forwardRef<SVGElement, IconProps>((props, ref) => (
    <Icon comp={SnoozingSvg} forwardedRef={ref} {...props} fallbackAlt="Snoozing" />
  )),
};

export default Icons;
